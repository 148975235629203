import 'styles/components/_episode-resource-list.scss';
import Component from 'scripts/components/component.js';
import EpisodeCondensed from 'scripts/components/EpisodeCondensed';
import EpisodePreview from 'scripts/components/EpisodePreview';
import ListNoResults from 'scripts/components/ListNoResults';
import Paginate from 'scripts/components/Paginate';
import ResourceCondensed from 'scripts/components/ResourceCondensed';
import ResourcePreview from 'scripts/components/ResourcePreview';

export default class EpisodeResourceList extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}

    EpisodePreview.constructorAll(this.element);
    EpisodeCondensed.constructorAll(this.element);
    ResourcePreview.constructorAll(this.element);
    ResourceCondensed.constructorAll(this.element);
    new Paginate(this.element);
    new ListNoResults(this.element);
	}
}
