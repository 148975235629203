import 'styles/components/_resource-condensed.scss';
import Component from 'scripts/components/component.js';
import ResourceIcon from 'scripts/components/ResourceIcon';

export default class ResourceCondensed extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}

    new ResourceIcon(this.element);
	}
}
