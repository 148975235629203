import 'styles/components/_content-player.scss';
import Component from 'scripts/components/component.js';
import ResourceIcon from 'scripts/components/ResourceIcon';
import VideoPlayer from 'scripts/components/VideoPlayer';

export default class ContentPlayer extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}

    new VideoPlayer(this.element);
    new ResourceIcon(this.element);
	}
}
