import 'styles/components/_line-tabs.scss';
import Component from 'scripts/components/component.js';

export default class LineTabs extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}
	}
}