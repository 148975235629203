import 'styles/components/_header-line.scss';
import Component from 'scripts/components/component.js';

export default class HeaderLine extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}
	}
}