import 'styles/components/_episode-preview.scss';
import Component from 'scripts/components/component.js';
import EpisodeIcon from 'scripts/components/EpisodeIcon';
import ResourceIcon from 'scripts/components/ResourceIcon';
import Tags from 'scripts/components/Tags';

export default class EpisodePreview extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}

    new Tags(this.element);
    ResourceIcon.constructorAll(this.element);
    new EpisodeIcon(this.element);
	}
}
