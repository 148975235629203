import 'styles/modules/_page.scss';
import ContentFilter from 'scripts/components/ContentFilter';
import Header from 'scripts/components/Header';
import Footer from 'scripts/components/Footer';
import PageBanner from 'scripts/components/PageBanner';
import "bootstrap/js/src/tab";
import PillButton from 'scripts/components/PillButton';
import Tooltip from "bootstrap/js/src/tooltip";

export default class Page {
  constructor(element) {
    this.element = element;
    this.elements = {};

    if (window.siteData) {
      this.siteData = window.siteData;
    }

    this.header = new Header(this.element);
    this.footer = new Footer(this.element);
    this.filter = new ContentFilter(this.element);
    new PageBanner(this.element);

    PillButton.constructorAll(this.element);

    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }
}
