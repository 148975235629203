import 'styles/components/_video-cautions.scss';
import Component from 'scripts/components/component.js';

export default class VideoCautions extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}
	}
}
