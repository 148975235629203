import 'styles/components/_content-filter.scss';
import Component from 'scripts/components/component.js';

export default class ContentFilter extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}

    this.elements.gradeField = this.queryElement('grade-field');
    this.elements.topicField = this.queryElement('topic-field');
    this.elements.contentTypeField = this.queryElement('content-type-field');

    const searchParams = new URLSearchParams(window.location.search);

    this.grade = searchParams.get('grade');
    this.topic = searchParams.get('topic');
    this.contentType = searchParams.get('contentType');

    this.load();
	}

  load() {
    if (this.elements.gradeField && this.grade) {
      this.elements.gradeField.value = this.grade;
    }

    if (this.elements.topicField && this.topic) {
      this.elements.topicField.value = this.topic;
    }

    if (this.elements.contentTypeField && this.contentType) {
      this.elements.contentTypeField.value = this.contentType;
    }
  }
}
