import 'styles/components/_component.scss';

export default class Component {
  /**
   * Constructor
   *
   * @param {Element} element
   */
  constructor(element) {
    if (element) {
      this.element = (this.isRootElement(element)) ? element : element.querySelector(this.componentSelector);
    }

    if (window.siteData) {
      this.siteData = window.siteData;
    }

    this.elements = {};
  }

  /**
   * Constructor all
   * Query element and create instances for all matching slectors.
   *
   * @param {Element} element
   * @return {NodeList}
   */
  static constructorAll(element) {
    let instances = [];

    if (element) {
      element.querySelectorAll(this.componentSelector).forEach((el) => {
        let instanceArguments = [...arguments];
        instanceArguments[0] = el;

        instances.push(new this(...instanceArguments));
      });
    }

    return instances;
  }

  /**
   * Get component name
   *
   * @returns {string}
   */
  get componentName() {
    return this._componentName;
  }

  /**
   * Component name from static class name
   *
   * @returns {string}
   */
  static get componentName() {
    return this.name;
  }

  /**
   * Get component selector
   * Shortcut for retrieving selector from static method
   *
   * @return {string}
   */
  get componentSelector() {
    // Intentional use of `this.constructor.` to referenced the child static class
    return this.constructor.componentSelector;
  }

  /**
   * Static implementation of get component selector
   */
  static get componentSelector() {
    return '.c-' + this.toKebabCase(this.componentName);
  }

  /**
   * Build element selector
   *
   * @param {string} element
   * @return {string}
   */
  buildQueryElementSelector(element) {
    return this.componentSelector + this.buildQueryElementGlue + element;
  }

  /**
   * Build modifier selector
   *
   * @param {string} modifier
   * @return {string}
   */
  buildQueryModifierSelector(modifier) {
    return this.componentSelector + this.buildQueryModifierGlue + modifier;
  }

  /**
   * Query
   *
   * @param {string} selector
   * @returns {Element}
   */
  query(selector) {
    return this.element.querySelector(selector);
  }

  /**
   * Query all
   *
   * @param {string} selector
   * @returns {NodeList}
   */
  queryAll(selector) {
    return this.element.querySelectorAll(selector);
  }

  /**
   * Query element
   *
   * @param {string} elementName
   * @returns {Element}
   */
  queryElement(elementName) {
    return this.query(this.buildQueryElementSelector(elementName));
  }

  /**
   * Query descendent all
   *
   * @param {string} elementName
   * @returns {NodeList}
   */
  queryElementAll(elementName) {
    return this.queryAll(this.buildQueryElementSelector(elementName));
  }

  /**
   * Query modifier
   *
   * @param {string} modifierName
   * @returns {Element}
   */
  queryModifier(modifierName) {
    return this.query(this.buildQueryModifierSelector(modifierName));
  }

  /**
   * Query modifier
   *
   * @param {string} modifierName
   * @returns {NodeList}
   */
  queryModifierAll(modifierName) {
    return this.queryAll(this.buildQueryModifierSelector(modifierName));
  }

  /**
   * Is root element
   */
  isRootElement(element) {
    return element.matches(this.componentSelector);
  }

  /**
   * Is element
   *
   * @param {Element|boolean} element
   * @param {string} elementName
   * @return {boolean}
   */
  isElement(element, elementName) {
    return element.matches(this.buildQueryElementSelector(elementName));
  }

  /**
   * Is modifier
   *
   * @param {Element|boolean} element
   * @param {string} modifierName
   * @return {boolean}
   */
  isModifier(element, modifierName) {
    return element.matches(this.buildQueryModifierSelector(modifierName));
  }

  /**
   * To kebab case
   *
   * @return {string}
   */
  static toKebabCase(string) {
    return string.split(/(?=[A-Z])/).join('-').toLowerCase();
  }

  /**
   * To camel case
   *
   * @return {string}
   */
  static toCamelCase(string) {
    return string.split(/_+|-+/).map(function (value, i) {
      return (i) ? value.charAt(0).toUpperCase() + value.slice(1) : value;
    }).join('');
  }

  /**
   * Element glue
   *
   * @return {string}
   */
  get buildQueryElementGlue() {
    return '__';
  }

  /**
   * Modifier glue
   *
   * @return {string}
   */
  get buildQueryModifierGlue() {
    return '--';
  }
}
