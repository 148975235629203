import 'styles/components/_episode-list.scss';
import Component from 'scripts/components/component.js';
import ListNoResults from 'scripts/components/ListNoResults';
import Paginate from 'scripts/components/Paginate';

export default class EpisodeList extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}

    new Paginate(this.element);
    new ListNoResults(this.element);
	}
}
