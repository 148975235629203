import 'styles/components/_episode-condensed.scss';
import Component from 'scripts/components/component.js';
import EpisodeIcon from 'scripts/components/EpisodeIcon';

export default class EpisodeCondensed extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}

    new EpisodeIcon(this.element);
	}
}
