import 'styles/components/_content-meta.scss';
import Component from 'scripts/components/component.js';
import Tags from 'scripts/components/Tags';

export default class ContentMeta extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}

    new Tags(this.element);
	}
}
