import 'styles/components/_subscribe-form.scss';
import Component from 'scripts/components/component.js';

export default class SubscribeForm extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}

    this.elements.form = this.queryElement('form');
    this.elements.email = this.queryElement('email');
    this._isSubmitting = false;
    this._status = 'input';

    this.events();
	}

  events() {
    this.elements.form.addEventListener('submit', (e) => {
      e.preventDefault();

      this.submit(e);
    });
  }

  get isSubmitting() {
    return this._isSubmitting;
  }

  set isSubmitting(value) {
    this._isSubmitting = value;
  }

  get status() {
    return this._status;
  }

  set status(value) {
    this._status = value;
    this.element.setAttribute('data-status', value);
  }

  submit() {
    if (this.isSubmitting && this.status === 'input') {
      return;
    }
    this.isSubmitting = true;

    const formData = new FormData();

    formData.append('email', this.elements.email.value);
    formData.append(this.siteData.csrf.name, this.siteData.csrf.token);

    fetch(`${this.siteData.siteUrl}subscribe`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: formData
    })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert("An error occurred. Please try again later.");
      })
      .then((response) => {
        return response.json().then((data) => {
          return {
            status: response.status,
            data
          };
        });
      })
      .then((response) => {
        this.isSubmitting = false;

        if (response.status === 200) {
          this.status = 'success';
        } else {
          const message = (response.data && response.data.message)
            ? response.data.message : 'An error occurred. Please try again later.';

          // eslint-disable-next-line no-alert
          alert(message);
        }
      });
  }
}
