import 'styles/components/_header.scss';
import Component from 'scripts/components/component.js';
import { sitePath, locationHref, isInternal } from 'scripts/utils/path';
import Breakpoint from "@curiousmedia/breakpoint";
import breakpoints from "../breakpoints";
import ModalAccessibility from '@curiousmedia/modal-accessibility';

export default class Header extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}

    this.elements.nav = this.queryElement('nav');
    this.elements.menuItem = this.queryElementAll('menu-item');
    this.elements.menuToggle = this.queryElement('menu-toggle');
    this.elements.searchToggle = this.queryElement('search-toggle');
    this.elements.searchField = this.queryElement('search-field');
    this.elements.searchForm = this.queryElement('search-form');
    this.elements.menuItems = this.queryElement('menu-items');
    this.elements.inner = this.queryElement('inner');

    this.bp = new Breakpoint(breakpoints);
    this.accessibility = new ModalAccessibility(this.elements.nav, () => {
      this.open = false;
    });
    this.path = sitePath(locationHref());

    this.load();
    this.events();
    this.resize();
	}

  load() {
    // Add "active" state to menu allowing Craft to cache header globally
    this.elements.menuItem.forEach((item) => {
      const a = item.querySelector('a');

      if (Array.isArray(this.path) && isInternal(a.href)) {
        const path = sitePath(a.href);

        if (Array.isArray(path) && (path[0] === this.path[0] || (path.length === 0 && this.path.length === 0))) {
          item.classList.add(Header.componentSelector.substr(1) + '--active');
        }
      }
    });

    // Change search path if in professional development section
    if (this.path[0] && this.path[0] === window.siteData.professionalSlug) {
      this.elements.searchForm.action = `${window.siteData.siteUrl}${window.siteData.professionalSlug}/search`;
    }

    this.elements.menuToggle.addEventListener('click', () => {
      this.open = !this.open;
    });

    this.elements.searchToggle.addEventListener('click', () => {
      this.search = !this.search;
    });
  }

  events() {
    window.addEventListener('resize', this.resize.bind(this));
  }

  resize() {
    this.nav = this.bp.any('sm', 'md') ? 'narrow' : 'wide';

    if (this.nav === 'wide') {
      this.open = false;
    }
  }

  set nav(nav) {
    this._nav = nav;
    this.element.setAttribute('data-nav', nav);
  }

  get nav() {
    return this._nav;
  }

  set open(open) {
    this._open = open;
    this.element.setAttribute('data-open', open ? 1 : 0);

    if (!open && this.nav === 'wide') {
      this.accessibility.priorFocusedElement = null;
      this.accessibility.close();
    } else {
      if (open) {
        this.elements.searchToggle.tabIndex = -1;
        this.elements.searchField.tabIndex = -1;

        this.accessibility.open();
      } else {
        this.accessibility.close();
      }
    }

    if (!open) {
      this.elements.searchToggle.removeAttribute('tabIndex');
      this.elements.searchField.removeAttribute('tabIndex');
    }
  }

  get open() {
    return this._open;
  }

  set search(search) {
    this._search = search;
    this.element.setAttribute('data-search', search ? 1 : 0);

    if (search) {
      this.elements.searchField.focus();
      this.elements.menuItems.setAttribute('aria-hidden', true);
    } else {
      this.elements.menuItems.removeAttribute('aria-hidden');
    }
  }

  get search() {
    return this._search;
  }
}
