import 'styles/components/_resource-list.scss';
import Component from 'scripts/components/component.js';
import ListNoResults from 'scripts/components/ListNoResults';
import Paginate from 'scripts/components/Paginate';
import ResourcePreview from 'scripts/components/ResourcePreview';

export default class ResourceList extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}

    ResourcePreview.constructorAll(this.element);
    new Paginate(this.element);
    new ListNoResults(this.element);
	}
}
