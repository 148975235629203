import 'styles/components/_content-recommendations.scss';
import Component from 'scripts/components/component.js';
import EpisodeResourceList from 'scripts/components/EpisodeResourceList';

export default class ContentRecommendations extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}

    new EpisodeResourceList(this.element);
	}
}
