export function siteUrl() {
  return window.siteData.siteUrl.replace(/\/+$|\?.+|\/\?.+|#.+|\/#.+/, '') + '/';
}

export function locationOrigin() {
  return window.location.origin.replace(/\/+$|\?.+|\/\?.+|#.+|\/#.+/, '') + '/';
}

export function locationHref() {
  return window.location.href.replace(/\/+$|\?.+|\/\?.+|#.+|\/#.+/, '') + '/';
}

export function sitePath(url) {
  const su = siteUrl();

  return (url.startsWith(su)) ? url.substr(su.length).split('/').filter((p) => p) : false;
}

export function isInternal(url) {
  return url.replace(/\/+$/, '').startsWith(siteUrl().replace(/\/+$/, ''));
}
