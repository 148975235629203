import 'styles/components/_footer.scss';
import Component from 'scripts/components/component.js';
import FooterMenu from 'scripts/components/FooterMenu';
import SubscribeForm from 'scripts/components/SubscribeForm';

export default class Footer extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}

    new SubscribeForm(this.element);
    FooterMenu.constructorAll(this.element);
	}
}
