import 'styles/components/_content-details.scss';
import Component from 'scripts/components/component.js';
import LineTabs from 'scripts/components/LineTabs';
import Pbslm from 'scripts/components/Pbslm';
import VideoCaution from 'scripts/components/VideoCaution';

export default class ContentDetails extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}

    new LineTabs(this.element);
    new Pbslm(this.element);
    new VideoCaution(this.element);
	}
}
